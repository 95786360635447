/* Example styling to make the page look more attractive */
.welcome-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #e0f7fa, #f1f8e9);
  padding: 0 20px;
  box-sizing: border-box;
}

.welcome-header {
  text-align: center;
  margin-bottom: 40px;
}

.welcome-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #00796b;
}

.welcome-subtitle {
  font-size: 1.2rem;
  color: #616161;
}

.welcome-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
